<template>
  <div id="audit-log">
    <div class="page-title px-4 mb-4">
      <h2>
        <span class="me-2">Audit Logs</span>
      </h2>
    </div>

    <div>
      <v-row class="mt-1 mb-3" style="align-items: center">
        <v-col cols="5">
          <v-text-field id="startDate" v-model="startDate" label="Start date" outlined hide-details dense type="date" />
        </v-col>
        <v-col cols="5">
          <v-text-field id="endDate" v-model="endDate" label="End date" outlined hide-details dense type="date" />
        </v-col>
        <v-col cols="2">
          <v-btn color="primary" style="min-width: 100%" @click="fetchData">Go</v-btn>
        </v-col>
      </v-row>
    </div>

    <div v-if="loading" class="fill-height d-flex justify-center align-center">
      <Loader />
    </div>

    <div v-else>
      <div v-if="auditLogs">
        <v-card>
          <div class="px-3">
            <v-row>
              <v-spacer></v-spacer>

              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="auditLogsSearch"
                  append-icon=""
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <div>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="[
                    {
                      text: 'Timestamp',
                      value: 'timestamp',
                      sortable: true,
                    },
                    {
                      text: 'Type',
                      value: 'type',
                      sortable: true,
                    },
                    {
                      text: 'ID',
                      value: 'id',
                      sortable: true,
                    },
                    {
                      text: 'User',
                      value: 'user',
                      sortable: true,
                    },
                    {
                      text: 'Message',
                      value: 'message',
                      sortable: true,
                    },
                  ]"
                  :items="auditLogsData"
                  :custom-sort="customSort"
                  :search="auditLogsSearch"
                  :sort-by.sync="auditLogsSortBy"
                  :sort-desc.sync="auditLogsSortDesc"
                  :must-sort="true"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item.timestamp }}</td>
                      <td>{{ item.type }}</td>
                      <td>{{ item.id }}</td>
                      <td>{{ item.user }}</td>
                      <td>{{ item.message_formatted }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>

      <div v-else>
        <span class="font-weight-semibold text--primary"> No Audit Logs available </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import Loader from "@/components/Loader.vue"
import store from "@/store"

dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: "AuditLogs",
  metaInfo: {
    title: "Audit Logs",
  },
  components: {
    Loader,
  },
  data() {
    return {
      currentTab: "",
      tabs: [],
      startDate: dayjs.tz(new Date(), "Pacific/Auckland").subtract(1, "week").format("YYYY-MM-DD"),
      endDate: dayjs.tz(new Date(), "Pacific/Auckland").subtract(1, "day").format("YYYY-MM-DD"),
      loading: false,
      auditLogs: [],
      auditLogsRequest: axios.CancelToken.source(),
      auditLogsSortBy: "timestamp",
      auditLogsSortDesc: true, // true for descending order, false for ascending order
      auditLogsSearch: "",
    }
  },
  computed: {
    auditLogsData() {
      if (!this.auditLogs.length) {
        return []
      }

      const rows = this.auditLogs.map(item => {
        item.message_formatted = JSON.stringify(JSON.parse(item.message), null, 4)

        return item
      })

      return rows
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loading = true
      this.auditLogs = []

      // Ignore time on start & end date
      const startDate = dayjs.tz(this.startDate, "Pacific/Auckland").format("YYYY-MM-DD 00:00:00")
      const endDate = dayjs.tz(this.endDate, "Pacific/Auckland").format("YYYY-MM-DD 23:59:59")

      try {
        const results = await this.fetchAuditLogs(startDate, endDate)

        if (Array.isArray(results) && results.length > 0) {
          this.auditLogs = results
        } else {
          console.warn("No audit logs found for the given date range.")
        }
      } catch (error) {
        console.error("Error fetching audit logs:", error)
      }

      this.loading = false
    },
    async fetchAuditLogs(startDate, endDate) {
      // Cancel previous request if needed
      this.auditLogsRequest.cancel("New fetch requested")
      this.auditLogsRequest = axios.CancelToken.source()

      // Await the axios response
      return store.dispatch("getAuditLogs", {
        start_datetime: startDate,
        end_datetime: endDate,
        cancelToken: this.auditLogsRequest.token,
      })
    },
    customSort(items, sortBy, sortDesc) {
      const sortByField = sortBy[0] ?? ""
      const sortDescending = sortDesc[0] ?? false

      items.sort((a, b) => {
        let sortA = a[sortByField]
        let sortB = b[sortByField]

        // Special handling of field sorting when required
        switch (sortByField) {
          case "upload": {
            sortA = a.upload_bytes
            sortB = b.upload_bytes

            break
          }
          case "download": {
            sortA = a.download_bytes
            sortB = b.download_bytes

            break
          }
          case "total_usage": {
            sortA = a.total_bytes
            sortB = b.total_bytes

            break
          }
          default:
            break
        }

        if (sortA > sortB) return 1
        if (sortA < sortB) return -1

        return 0
      })

      // Reverse order if header clicked again
      if (sortDescending) {
        items.reverse()
      }

      return items
    },
  },
}
</script>

<style scoped></style>
